import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/common'

const NotFoundPage = () => (
  <Layout>
    <h1>Error 404</h1>
    <article>
            Page not found, <Link to="/">return home</Link> to start over
    </article>
  </Layout>
)

export default NotFoundPage
